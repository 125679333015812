import { useParams } from "next/navigation"
import { useMutation, type UseMutationOptions } from "@tanstack/react-query"

import type { AlertPresetKeys } from "~/lib/alert"
import { SHOPIFY_NEWSLETTER_PARAMS } from "~/lib/api/constants"
import { requestNextApiEndpoint } from "~/lib/api/utils/request-endpoint"
import { getI18nKey } from "~/lib/i18n/utils/get-i18n"
import { getProcessedLocale } from "~/lib/i18n/utils/get-processed-locale"
import { useProcessNewCustomerTags } from "~/hooks/account/useProcessNewCustomerTags"
import type { NewsletterMutationProps } from "~/components/ui/Newsletter"

export default function useShopifyNewsletterMutation(
  mutationOptions?: UseMutationOptions<unknown, unknown, NewsletterMutationProps>
) {
  const params = useParams()
  const lang = getI18nKey(getProcessedLocale(params?.locale), "lang")

  const customerTags = useProcessNewCustomerTags(params?.locale)

  return useMutation<unknown, Error, NewsletterMutationProps>(
    async ({ email, acceptsMarketing = true }) => {
      return await requestNextApiEndpoint(SHOPIFY_NEWSLETTER_PARAMS, {
        payload: {
          email,
          lang,
          tags: customerTags,
          acceptsMarketing,
        },
      })
    },
    {
      ...mutationOptions,
      onSuccess: (data, ...rest) => {
        mutationOptions?.onSuccess && mutationOptions.onSuccess(data, ...rest)
      },
      onError: (err, ...rest) => {
        const errMessage = JSON.parse(err?.message) as { success: boolean; data: AlertPresetKeys }
        mutationOptions?.onError?.(errMessage?.data, ...rest)
      },
    }
  )
}
